import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';

import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule } from '@ngx-translate/core';

import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { HeaderComponent } from './default-layout/header/header.component';
import { FooterComponent } from './default-layout/footer/footer.component';

import { SharedUiDarkModeTogglerModule } from '@gan/shared/ui-dark-mode-toggler';
import { SchoolFeatureNotificationMessagesModule } from '@gan/school/feature-notification-messages';
import { SharedUiIconModule } from '@gan/shared/ui-icon';
import { CoreFeatureWebPushNotificationsModule } from '@gan/core/feature-web-push-notifications';
import { SharedUiButtonModule } from '@gan/shared/ui-button';

const components = [DefaultLayoutComponent, HeaderComponent, FooterComponent];

const matModules = [MatToolbarModule];

const ganModules = [
  SharedUiDarkModeTogglerModule,
  SchoolFeatureNotificationMessagesModule,
  SharedUiIconModule,
  SharedUiButtonModule,
  CoreFeatureWebPushNotificationsModule,
];

@NgModule({
  declarations: [...components],
  imports: [...matModules, ...ganModules, CommonModule, RouterOutlet, RouterLink, TranslateModule],
})
export class LayoutModule {}
