import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, finalize, skip, startWith, tap } from 'rxjs/operators';
import { LoaderStateModel } from './loader-state.model';

const loaderSubject = new Subject<LoaderStateModel>();
export const loaderState$ = loaderSubject.asObservable();

/**
 * show spinner
 */
export const showSpinner = () => loaderSubject.next({ show: true } as LoaderStateModel);

/**
 * hide spinner
 */
export const hideSpinner = () => loaderSubject.next({ show: false } as LoaderStateModel);

/**
 * this function is created for usage within .pipe before .subscribe
 * it will show spinner before request & hide it in any case of response
 * (success, error, cancellation)
 * preferable to use it at first place inside of .pipe()
 */
export const useSpinner =
  () =>
  <T>(source: Observable<T>) =>
    source.pipe(
      startWith('🖖' as any),
      tap((e) => e === '🖖' && showSpinner()),
      skip(1),
      finalize(() => hideSpinner())
    );

@Component({
  selector: 'gan-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  show = false;
  trueStates = 0;
  falseStates = 0;

  ngOnInit() {
    loaderState$.pipe(delay(100)).subscribe(({ show }: LoaderStateModel) => {
      show ? this.trueStates++ : this.falseStates++;
      if (this.trueStates <= this.falseStates) {
        this.trueStates = this.falseStates = 0;
      }
      this.show = this.trueStates > this.falseStates;
    });
  }
}
