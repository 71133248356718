import { Component, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { OneSignal } from 'onesignal-ngx';

@Component({
  selector: 'gan-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBellComponent {
  readonly subscriptionState$ = new BehaviorSubject<boolean>(false);
  readonly notificationPermissionState$ = new BehaviorSubject<boolean>(false);
  readonly icon$ = combineLatest([this.subscriptionState$, this.notificationPermissionState$]).pipe(
    map(([subscriptionState, notificationPermission]: boolean[]) => {
      return !notificationPermission || subscriptionState ? 'notification-bell-on' : 'notification-bell-off';
    })
  );

  constructor(private readonly onesignal: OneSignal) {
    // Listen to permission state
    this.onesignal.on('notificationPermissionChange', (v: { to: NotificationPermission }) => {
      this.notificationPermissionState$.next(v.to === 'granted');
    });

    Promise.all([this.onesignal.getSubscription(), this.onesignal.getNotificationPermission()]).then(
      ([subscriptionState, notificationPermission]: [boolean, NotificationPermission]) => {
        this.subscriptionState$.next(subscriptionState);
        this.notificationPermissionState$.next(notificationPermission === 'granted');
      }
    );
  }

  /**
   * When clicked, checks if browser's notification permissions are granted
   * Once user allows them, onesignal subscription state will be set to true
   * Later, when toggling subscribe/unsubscribe button app will update subscription state
   * Everything will be handled on onesignal side
   */
  toggleOneSignalSubscription() {
    this.onesignal.getNotificationPermission().then((notificationPermission) => {
      if (notificationPermission !== 'granted') {
        this.onesignal.showNativePrompt();

        return;
      }

      this.onesignal.getSubscription().then((state) => {
        this.onesignal.setSubscription(!state);
        this.subscriptionState$.next(!state);
      });
    });
  }
}
