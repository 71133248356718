export const pageQuery = `
  title,
  "description": body[]
`;

export const pageQueryWithImage = `
  title,
  "description": body[]{
    ...,
    "image": {
      "url": image.asset->url,
      "alt": alt,
    }
  }
`;
