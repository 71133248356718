import { Injectable } from '@angular/core';

import { OneSignal } from 'onesignal-ngx';

import { environment } from '@gan/core/util-environment';

import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { merge, Observable, timer } from 'rxjs';
import { distinctUntilChanged, filter, first, shareReplay, skipWhile, switchMap } from 'rxjs/operators';

import { UserNotificationsApiService } from '@gan/common/data-user-notifications';

import { NotificationSubscriptionInterface } from '../interfaces';

@Injectable()
export class WebPushNotificationsService {
  notificationPermissionState$ = this.getPermissionStateStream();

  constructor(
    private readonly onesignal: OneSignal,
    private readonly userNotificationsApiService: UserNotificationsApiService
  ) {}

  init(options: NotificationSubscriptionInterface) {
    this.onesignal.init({
      appId: environment.ONESIGNAL_APP_ID,
    });

    this.createUserSubscriptionOnceGranted(options);
  }

  private createUserSubscriptionOnceGranted(options: NotificationSubscriptionInterface) {
    this.notificationPermissionState$
      .pipe(
        filter((v) => v === 'granted'),
        first(),
        switchMap(
          () =>
            timer(1000).pipe(
              switchMap(() => fromPromise(this.onesignal.getUserId())),
              skipWhile((val) => val === null),
              first()
            ) as Observable<string>
        )
      )
      .subscribe((playerId) => {
        if (!options.disableNotificationPlayer) {
          this.userNotificationsApiService
            .createUserNotificationSubscription({
              homeAccountId: options.homeAccountId,
              playerId,
            })
            .subscribe();
        }
      });
  }

  private getPermissionStateStream() {
    return merge(
      fromPromise(this.onesignal.getNotificationPermission()),
      new Observable<NotificationPermission>((observer) => {
        this.onesignal.on('notificationPermissionChange', (v: { to: NotificationPermission }) => observer.next(v.to));
      })
    ).pipe(distinctUntilChanged(), shareReplay());
  }
}
