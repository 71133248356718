import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { Observable, Subject, timer } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { Moment } from 'moment/moment';
import * as moment from 'moment';

import {
  NotificationMessageInterface,
  NotificationMessagesService,
  NotificationMessagesSignalRService,
} from '@gan/common/data-notification-messages';

import { SignalREventModel, SignalRStateEnum } from '@gan/core/util-signalr';
import { DATE_TIME_PROPERTIES } from '@gan/core/util-date-time-helper';

@Component({
  selector: 'gan-notification-messages',
  templateUrl: './notification-messages.component.html',
  styleUrls: ['./notification-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessagesComponent implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  readonly messages: NotificationMessageInterface[] = [];
  readonly dateTimeProps = DATE_TIME_PROPERTIES;
  readonly time$: Observable<Moment> = timer(0, 1000).pipe(
    map(() => moment()),
    shareReplay(1)
  );

  constructor(
    private notificationMessagesService: NotificationMessagesService,
    private notificationMessagesSignalRService: NotificationMessagesSignalRService,
    private cdr: ChangeDetectorRef
  ) {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.notificationMessagesSignalRService.disconnectHub();
  }

  private init(): void {
    this.notificationMessagesService
      .getNotificationMessages()
      .pipe(
        tap((messages: NotificationMessageInterface[]) => {
          const now = new Date().getTime();

          this.messages.push(...messages.filter((message) => new Date(message.validTo).getTime() > now));

          this.cdr.detectChanges();

          this.notificationMessagesSignalRService.startHubConnection().then(() => {
            this.subscribeOnNotificationMessagesChannel();
          });
        })
      )
      .subscribe();
  }

  private subscribeOnNotificationMessagesChannel(): void {
    this.notificationMessagesSignalRService.hubEvent
      .pipe(
        takeUntil(this.destroy$),
        tap((response: SignalREventModel<NotificationMessageInterface>[]) => {
          console.log(response);

          const now = new Date().getTime();

          response.forEach((notification) => {
            const index = this.messages.findIndex((message) => message.id === notification.key);

            switch (notification.state) {
              case SignalRStateEnum.MODIFIED:
                if (index > -1) {
                  if (new Date(notification.value.validTo).getTime() > now) {
                    this.messages[index] = notification.value;
                  } else {
                    this.messages.splice(index, 1);
                  }
                } else {
                  this.messages.unshift(notification.value);
                }

                break;
              case SignalRStateEnum.REMOVED:
                if (index > -1) {
                  this.messages.splice(index, 1);
                }

                break;
            }
          });

          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }
}
