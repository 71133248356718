import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NotificationInterface, SnackbarType } from '@gan/shared/ui-snackbar';

@Component({
  selector: 'gan-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent {
  @Input() notification!: NotificationInterface;
  @Input() type!: SnackbarType;

  @Output() dismiss = new EventEmitter<void>();
}
