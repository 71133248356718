import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SetTitleGuard } from './guards/set-title.guard';

@NgModule({
  imports: [CommonModule],
  providers: [SetTitleGuard],
})
export class SharedUiTitleModule {}
