import { Pipe, PipeTransform } from '@angular/core';

import { UiDateService } from '../services/ui-date.service';
import { GanDateFormats } from '../models/ui-date.model';

@Pipe({
  name: 'ganDate',
})
export class UiDatePipe implements PipeTransform {
  constructor(private readonly uiDateService: UiDateService) {}

  transform(date: Date | string | number, options: GanDateFormats = 'date_time'): string {
    switch (options) {
      case 'date':
        return this.uiDateService.toDateString(date);
      case 'relative_date':
        return this.uiDateService.toRelativeDateString(date);
      case 'time':
        return this.uiDateService.toTimeString(date);
      case 'relative_time':
        return this.uiDateService.toRelativeTimeString(date);
      case 'ordinal':
        return this.uiDateService.toOrdinalDate(date);
      case 'date_time':
      default:
        return this.uiDateService.toDateTimeString(date);
    }
  }
}
