import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { LocalStorageService } from '@gan/core/util-local-storage';
import { SanityBaseService } from './sanity-base.service';

import { sanityNorgeClientCredentials, pageQuery, pageQueryWithImage } from '../helpers';

@Injectable()
export class SanityNorgeService extends SanityBaseService {
  readonly config = sanityNorgeClientCredentials;

  constructor(protected override readonly localStorageService: LocalStorageService) {
    super(localStorageService);
  }

  getSubscriptionInstructions(device: string): Observable<any> {
    const request = this.config.fetch(
      `* [ slug.current == "skole-subscribe-${device}" && language == "${this.selectedLanguage}"] {${pageQueryWithImage}}[0]`
    );

    return fromPromise(request);
  }

  getGDPR(): Observable<any> {
    const request = this.config.fetch(
      `* [ slug.current == "skole-gdpr" && language == "${this.selectedLanguage}"] {${pageQuery}}[0]`
    );

    return fromPromise(request);
  }
}
