import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SanityIntraService, SanityNorgeService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [SanityIntraService, SanityNorgeService],
})
export class CoreUtilSanityModule {}
