import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import localeNor from '@angular/common/locales/nb';
import localeNorExtra from '@angular/common/locales/nb';
import { registerLocaleData } from '@angular/common';
import 'moment/locale/nb';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';

import { environment } from '@gan/core/util-environment';

import { CoreFeatureLanguageModule } from '@gan/core/feature-language';

import { AppComponent } from './app.component';
import { RouterConfigData } from './app-routes';

import { CommonUiSpinnerModule } from '@gan/common/ui-spinner';
import { CommonUiNotificationModule } from '@gan/common/ui-notification';
import { LayoutModule } from './layout/layout.module';
import { CoreUtilLocalStorageModule } from '@gan/core/util-local-storage';
import { SharedUiTitleModule } from '@gan/shared/ui-title';

registerLocaleData(localeNor, 'no', localeNorExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    RouterConfigData,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreFeatureLanguageModule,
    CommonUiSpinnerModule,
    CommonUiNotificationModule,
    LayoutModule,
    CoreUtilLocalStorageModule,
    SharedUiTitleModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enablePWA,
    }),
  ],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      useFactory: () => environment.LANGUAGE_DEFAULT.toUpperCase(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
