import { Pipe, PipeTransform } from '@angular/core';

import { Moment } from 'moment';
import * as moment from 'moment';

@Pipe({
  name: 'hasPassedTimeStamp',
})
export class HasPassedTimeStampPipe implements PipeTransform {
  transform(timestamp: Date | Moment | string, time: Date | Moment | string): boolean {
    return moment(timestamp).diff(moment(time), 'minutes') >= 0;
  }
}
