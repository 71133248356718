import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { environment } from '@gan/core/util-environment';

/**
 * language factory for language switcher in application
 * @param http - http client
 */
export function LanguageFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {
      prefix: 'assets/i18n/',
      suffix: `.json?version=${environment.I18N_VERSION}`,
    },
    {
      prefix: 'assets/i18n/common/',
      suffix: `.json?version=${environment.I18N_VERSION}`,
    },
  ]);
}
