import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonDataNotificationMessagesModule } from '@gan/common/data-notification-messages';

import { NotificationMessagesComponent } from './components/notification-messages/notification-messages.component';
import { SharedUiIconModule } from '@gan/shared/ui-icon';
import { CommonUiDatePipeModule } from '@gan/common/ui-date-pipe';

@NgModule({
  imports: [CommonModule, CommonDataNotificationMessagesModule, SharedUiIconModule, CommonUiDatePipeModule],
  declarations: [NotificationMessagesComponent],
  exports: [NotificationMessagesComponent],
})
export class SchoolFeatureNotificationMessagesModule {}
