import { HttpErrorResponse } from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MethodTypeEnum } from '../enums/method-type.enum';
import { NOTIFICATION_ERROR_TIME, NOTIFICATION_SUCCESS_TIME } from '../constants/constants';

import { NotificationInterface, SnackbarInterface } from '@gan/shared/ui-snackbar';

const buildSuccessMessage = (method: MethodTypeEnum, resourceName: string): NotificationInterface | undefined => {
  switch (method) {
    case MethodTypeEnum.CREATE:
      return { title: 'NOTIFICATION.CREATE.SUCCESS', resourceName };
    case MethodTypeEnum.UPDATE:
      return { title: 'NOTIFICATION.UPDATE.SUCCESS', resourceName };
    case MethodTypeEnum.DELETE:
      return { title: 'NOTIFICATION.DELETE.SUCCESS', resourceName };
    default:
      return;
  }
};

const buildErrorMessage = (
  method: MethodTypeEnum,
  resourceName: string,
  messageFromBE = ''
): NotificationInterface | undefined => {
  switch (method) {
    case MethodTypeEnum.GET:
      return { title: 'NOTIFICATION.GET.ERROR', resourceName, message: messageFromBE };
    case MethodTypeEnum.CREATE:
      return { title: 'NOTIFICATION.CREATE.ERROR', resourceName, message: messageFromBE };
    case MethodTypeEnum.UPDATE:
      return { title: 'NOTIFICATION.UPDATE.ERROR', resourceName, message: messageFromBE };
    case MethodTypeEnum.DELETE:
      return { title: 'NOTIFICATION.DELETE.ERROR', resourceName, message: messageFromBE };
    default:
      return;
  }
};

const createSuccessNotification = (notification: NotificationInterface | undefined) => {
  notification &&
    notificationsSubject.next({
      notification,
      time: NOTIFICATION_SUCCESS_TIME,
      type: 'SUCCESS',
    });
};

const createErrorNotification = (notification: NotificationInterface | undefined) => {
  notification &&
    notificationsSubject.next({
      notification,
      time: NOTIFICATION_ERROR_TIME,
      type: 'ERROR',
    });
};

const notificationsSubject = new Subject<SnackbarInterface>();

export const notifications$ = notificationsSubject.asObservable();

export class NotificationParams {
  constructor(public method: MethodTypeEnum, public resourceName: string, public showErrorAlert?: boolean) {
    showErrorAlert = showErrorAlert || showErrorAlert == undefined;
  }
}

/*
Show success notification for any non GET operations.
Show error notification on any error, unless showErrorAlert=false.
On error, exception is always thrown.
*/
export const useNotification =
  (params: NotificationParams) =>
  <T>(source: Observable<T>) =>
    source.pipe(
      tap(() => {
        if (params.method !== MethodTypeEnum.GET) {
          createSuccessNotification(buildSuccessMessage(params.method, params.resourceName));
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const msg =
          error?.error?.Message ||
          error?.error?.message ||
          error?.error?.detail ||
          `${error.status} ${error.statusText}`;
        if (params.showErrorAlert) {
          createErrorNotification(buildErrorMessage(params.method, params.resourceName, msg));
        }

        return throwError(() => new Error(msg));
      })
    );
