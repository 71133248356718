/**
 * language enum
 */
export enum LanguageEnum {
  EN = 'En',
  NO = 'No',
}

export interface MultiLangText {
  en: string;
  no: string;
}
