/**
 * check is array
 * @param value
 * @return boolean
 */
export const isArray = (value) => Array.isArray(value);

/**
 * check is object
 * @param value
 * @return boolean
 */
export const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

/**
 * check is number
 * @param value
 * @return boolean
 */
export const isNumber = (value) => typeof value === 'number';

/**
 * check is string
 */
export const isString = (value) => typeof value === 'string';

/**
 * check is null
 * @param value
 * @return boolean
 */
export const isNull = (value) => value === null;

/**
 * check is undefined
 * @param value
 * @return boolean
 */
export const isUndefined = (value) => value === undefined;

/**
 * check is null or undefined
 * @param value
 * @return boolean
 */
export const isNullOrUndefined = (value) => value === null || value === undefined;
