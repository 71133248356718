<div class="snackbar-container" [class]="type.toLowerCase()">
  <ng-container
    [ngTemplateOutlet]="snackBarTitle"
    [ngTemplateOutletContext]="{ title: notification.title, resourceName: notification.resourceName }"
  ></ng-container>

  <ng-container
    [ngTemplateOutlet]="snackBarMessage"
    [ngTemplateOutletContext]="{ message: notification.message }"
  ></ng-container>

  <button mat-icon-button (click)="dismiss.emit()">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<ng-template #snackBarTitle let-title="title" let-resourceName="resourceName">
  <p>
    {{ title | translate }} <span class="font-bold">{{ resourceName }}</span>
  </p>
</ng-template>

<ng-template #snackBarMessage let-message="message">
  <div *ngIf="message" class="bg-white p-1">
    <pre [innerHTML]="message" class="text-black whitespace-pre-wrap"></pre>
  </div>
</ng-template>
