/**
 * date time properties
 */
export const DATE_TIME_PROPERTIES = {
  defaultLocale: 'en-US',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultDateTimeFormat: 'dd.MM.yyyy HH:mm',
  defaultDateTimeWithSecondsFormat: 'dd.MM.yyyy HH:mm:ss',
  defaultTimeFormat: 'HH:mm',
  defaultTimeWithSecondsFormat: 'HH:mm:ss',
  kariHistoryDateTimeFormat: 'dd.MM.yy HH:mm:ss',
  siriHistoryDateTimeFormat: 'HH:mm:ss',
  realTimeTableTimeFormat: 'HH:mm',
  datePickerFormat: 'DD.MM.YYYY',
  backendSideFormat: 'YYYY-MM-DD',
  datePipeFormat: 'DD.MM.YYYY',
  dayDateFormat: 'dddd DD',
  dayDateMonthFormat: 'EEEE dd. LLLL',
  dayDateMonthShortFormat: 'EEE dd. LLL',
};

export const DATEPICKER_PROPERTIES = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: DATE_TIME_PROPERTIES.datePickerFormat,
    monthYearLabel: 'MMM YYYY',
  },
};
