import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiDateService } from './services/ui-date.service';

import { UiDatePipe } from './pipes/ui-date.pipe';
import { HasPassedTimeStampPipe } from './pipes/has-passed-timestamp.pipe';
import { TimeDiffPipe } from './pipes/time-diff.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [UiDatePipe, HasPassedTimeStampPipe, TimeDiffPipe],
  exports: [UiDatePipe, HasPassedTimeStampPipe, TimeDiffPipe],
  providers: [UiDateService],
})
export class CommonUiDatePipeModule {}
