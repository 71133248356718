import { Component, ChangeDetectionStrategy } from '@angular/core';

import { WebPushNotificationsService } from '@gan/core/feature-web-push-notifications';
import { LocalStorageService } from '@gan/core/util-local-storage';

@Component({
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultLayoutComponent {
  private readonly authKey = 'school.auth';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly webPushNotificationsService: WebPushNotificationsService
  ) {
    this.subscribeOnWebPushNotifications();
  }

  subscribeOnWebPushNotifications(): void {
    if (!this.localStorageService.hasValue(this.authKey)) {
      this.localStorageService.setValue(
        this.authKey,
        Date.now().toString(36) + Math.random().toString(36).substring(2)
      );
    }

    this.webPushNotificationsService.init({
      homeAccountId: this.localStorageService.getValue(this.authKey),
      disableNotificationPlayer: true,
    });
  }
}
