import { Injectable, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';

import { LanguageFactory } from './factories/language-factory';

import { ChoseTranslationPipe } from './pipes/chose-translation/chose-translation.pipe';

import { SetLanguageInterceptor } from './interceptors/set-language.interceptor';

export class HandleMissingTranslation implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    console.warn(
      `Missing translation or declaration of ${params.key}. Existing keys: ${JSON.stringify(
        params.translateService.translations
      )}`
    );
    return params.key;
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'No',
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: () => {
          return new HandleMissingTranslation();
        },
      },
    }),
  ],
  declarations: [ChoseTranslationPipe],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetLanguageInterceptor,
      multi: true,
    },
  ],
  exports: [ChoseTranslationPipe],
})
export class CoreFeatureLanguageModule {}
