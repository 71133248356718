import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { LocalStorageService } from '@gan/core/util-local-storage';
import { LanguageEnum } from '@gan/core/feature-language';

import { environment } from '@gan/core/util-environment';

@Component({
  selector: 'gan-school',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private translate: TranslateService, private localStorageService: LocalStorageService) {
    this.setLanguage();
  }

  /**
   * set language
   */
  setLanguage() {
    const hasLangValue = this.localStorageService.hasLanguageValue();

    if (hasLangValue) {
      const langValue = this.localStorageService.getLang();
      this.translate.setDefaultLang(langValue);
      this.localStorageService.setLang(langValue);
    } else {
      this.translate.setDefaultLang(environment.LANGUAGE_DEFAULT as LanguageEnum);
      this.localStorageService.setLang(environment.LANGUAGE_DEFAULT as LanguageEnum);
    }
  }
}
