<div class="hidden">
  <gan-dark-mode-toggler></gan-dark-mode-toggler>
</div>

<mat-toolbar class="main-header justify-between !px-5">
  <gan-link routerLink="how-to" class="pr-3 py-2 cursor-pointer">
    <gan-icon name="how-to"></gan-icon>
  </gan-link>

  <a class="block company-logo-container" href="/">
    <img class="company-icon" src="assets/images/company-logo/GA-skole-white.svg" alt="Skoletog" />
  </a>

  <gan-notification-bell></gan-notification-bell>
</mat-toolbar>
