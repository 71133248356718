import { Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum, MultiLangText } from '../../enums/language.enum';
import { LocalStorageService } from '@gan/core/util-local-storage';

@Pipe({ name: 'ganChooseTranslation' })
export class ChoseTranslationPipe implements PipeTransform {
  constructor(private readonly localStorageService: LocalStorageService) {}

  transform(value: MultiLangText | undefined): string {
    const currentLang: LanguageEnum = this.localStorageService.getLang() || LanguageEnum.NO;
    console.warn(`Translation for ${value}...`);
    try {
      return (value as any)[currentLang];
    } catch (e) {
      console.warn(`Translation for ${value} not found.`);
      return (value as any).toString();
    }
  }
}
