import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { LanguageFactory } from './factories/language-factory';

import { ChoseTranslationPipe } from './pipes/chose-translation/chose-translation.pipe';

import { SetLanguageInterceptor } from './interceptors/set-language.interceptor';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [ChoseTranslationPipe],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetLanguageInterceptor,
      multi: true,
    },
  ],
  exports: [ChoseTranslationPipe],
})
export class CoreFeatureLanguageModule {}
