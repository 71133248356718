import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnesignalNgxModule } from 'onesignal-ngx';

import { WebPushNotificationsService } from './services/web-push-notifications.service';

import { NotificationBellComponent } from './components/notification-bell/notification-bell.component';

import { CommonDataUserNotificationsModule } from '@gan/common/data-user-notifications';
import { SharedUiIconModule } from '@gan/shared/ui-icon';
import { SharedUiButtonModule } from '@gan/shared/ui-button';

@NgModule({
  imports: [
    CommonModule,
    OnesignalNgxModule,
    CommonDataUserNotificationsModule,
    SharedUiIconModule,
    SharedUiButtonModule,
  ],
  providers: [WebPushNotificationsService],
  declarations: [NotificationBellComponent],
  exports: [NotificationBellComponent],
})
export class CoreFeatureWebPushNotificationsModule {}
