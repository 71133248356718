import { Injectable } from '@angular/core';
import { isNull } from '@gan/core/util-type-check';
import { environment } from '@gan/core/util-environment';
import { LanguageEnum } from '@gan/core/feature-language';

@Injectable()
export class LocalStorageService {
  /**
   * set value to local storage
   * @param key   - local storage key
   * @param value - setting value
   */
  setValue(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  /**
   * get value from local storage
   * @param key - local storage key
   */
  getValue(key: string) {
    return localStorage.getItem(key);
  }

  /**
   * check if local storage has value
   * @param key - local storage key
   */
  hasValue(key: string) {
    return !isNull(localStorage.getItem(key));
  }

  /**
   * set language
   * @param lang - language
   */
  setLang(lang: LanguageEnum): void {
    localStorage.setItem(environment.LANGUAGE_STORAGE_KEY, lang.toString());
  }

  /**
   * get language
   */
  getLang(): LanguageEnum | null {
    const currentLang = this.getValue(environment.LANGUAGE_STORAGE_KEY);
    return (
      currentLang ? `${currentLang.charAt(0).toUpperCase()}${currentLang.slice(1)}` : environment.LANGUAGE_DEFAULT
    ).replace(/\"/g, '') as LanguageEnum;
  }

  /**
   * check if language value is not empty
   */
  hasLanguageValue(): boolean {
    return this.hasValue(environment.LANGUAGE_STORAGE_KEY);
  }
}
