import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationModel } from '../models/notification-model';
import { NOTIFICATION_ERROR_TIME, NOTIFICATION_SUCCESS_TIME } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly SUCCESS_TIME: number = NOTIFICATION_SUCCESS_TIME;
  readonly ERROR_TIME: number = NOTIFICATION_ERROR_TIME;

  private subject = new Subject<NotificationModel>();
  pool = this.subject.asObservable();

  /**
   * add success alert on the page
   * @param message - alert message to display in alert content
   * @param timeout - timeout (milliseconds), time when user can see message
   */
  success(message: string, timeout = this.SUCCESS_TIME): void {
    this.subject.next({
      message,
      time: timeout,
      type: 'SUCCESS',
    });
  }

  /**
   * add error alert on the page
   * @param message - alert message to display in alert content
   * @param timeout - timeout (milliseconds), time when user can see message
   */
  error(message: string, timeout: number = this.ERROR_TIME): void {
    this.subject.next({
      message,
      time: timeout,
      type: 'ERROR',
    });
  }
}
