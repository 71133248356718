<ng-container [ngSwitch]="type">
  <button
    *ngSwitchCase="'raised'"
    mat-raised-button
    [class]="classList"
    [color]="color"
    [disabled]="disabled"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>

  <button
    *ngSwitchCase="'stroked'"
    mat-stroked-button
    [class]="classList"
    [color]="color"
    [disabled]="disabled"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>

  <button
    *ngSwitchCase="'flat'"
    mat-flat-button
    [class]="classList"
    [color]="color"
    [disabled]="disabled"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>

  <button
    *ngSwitchCase="'icon'"
    mat-icon-button
    [class]="classList"
    [color]="color"
    [disabled]="disabled"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>

  <button
    *ngSwitchCase="'fab'"
    mat-fab
    [class]="classList"
    [color]="color"
    [disabled]="disabled"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>

  <button
    *ngSwitchCase="'mini_fab'"
    mat-mini-fab
    [class]="classList"
    [color]="color"
    [disabled]="disabled"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>

  <button
    *ngSwitchDefault
    mat-button
    [class]="classList"
    [color]="color"
    [disabled]="disabled"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>
</ng-container>

<ng-template #buttonTemplate>
  <ng-content></ng-content>
</ng-template>
