<div *ngIf="time$ | async as time" class="flex flex-col w-full mb-6">
  <ng-container *ngFor="let message of messages; let last = last">
    <div
      *ngIf="message.validTo | hasPassedTimeStamp : time"
      class="flex flex-grow items-center py-2 px-4 alert"
      [class.mb-3]="!last"
    >
      <gan-icon class="mr-2" name="warning"></gan-icon>

      <span class="text-xs" [innerText]="message.message"></span>
    </div>
  </ng-container>
</div>
