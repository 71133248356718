import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';

import { DarkModeTogglerComponent } from './components/dark-mode-toggler/dark-mode-toggler.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatSlideToggleModule, MatIconModule, MatButtonModule, TranslateModule],
  declarations: [DarkModeTogglerComponent],
  exports: [DarkModeTogglerComponent],
})
export class SharedUiDarkModeTogglerModule {}
