<ng-container [ngSwitch]="type">
  <a
    *ngSwitchCase="'raised'"
    [color]="color"
    [routerLink]="routerLink || href"
    [queryParams]="queryParams"
    [attr.href]="href"
    [target]="target"
    mat-raised-button
  >
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
  </a>

  <a
    *ngSwitchCase="'stroked'"
    [color]="color"
    [routerLink]="routerLink || href"
    [queryParams]="queryParams"
    [attr.href]="href"
    [target]="target"
    mat-stroked-button
  >
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
  </a>

  <a
    *ngSwitchCase="'flat'"
    [color]="color"
    [routerLink]="routerLink || href"
    [queryParams]="queryParams"
    [attr.href]="href"
    [target]="target"
    mat-flat-button
  >
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
  </a>

  <a
    *ngSwitchCase="'icon'"
    [color]="color"
    [routerLink]="routerLink || href"
    [queryParams]="queryParams"
    [attr.href]="href"
    [target]="target"
    mat-icon-button
  >
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
  </a>

  <a
    *ngSwitchCase="'fab'"
    [color]="color"
    [routerLink]="routerLink || href"
    [queryParams]="queryParams"
    [attr.href]="href"
    [target]="target"
    mat-fab
  >
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
  </a>

  <a
    *ngSwitchCase="'mini_fab'"
    [color]="color"
    [routerLink]="routerLink || href"
    [queryParams]="queryParams"
    [attr.href]="href"
    [target]="target"
    mat-mini-fab
  >
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
  </a>

  <a *ngSwitchDefault [color]="color" [routerLink]="routerLink || href" [attr.href]="href" [target]="target" mat-button>
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
  </a>
</ng-container>

<ng-template #linkTemplate>
  <ng-content></ng-content>
</ng-template>
