<div class="flex items-center text-sm gap-2 cursor-pointer" (click)="toggleControl.setValue(!toggleControl.value)">
  <ng-container *ngIf="!toggleControl.value">
    <span *ngIf="showLabel" [innerText]="'SETTINGS.THEME.LIGHT' | translate"></span>
    <mat-icon>light_mode</mat-icon>
  </ng-container>

  <ng-container *ngIf="toggleControl.value">
    <span *ngIf="showLabel" [innerText]="'SETTINGS.THEME.DARK' | translate"></span>
    <mat-icon>nightlight</mat-icon>
  </ng-container>
</div>
