import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Meta } from '@angular/platform-browser';

import { BehaviorSubject } from 'rxjs';

import { LocalStorageService } from '@gan/core/util-local-storage';

import { environment } from '@gan/core/util-environment';

@Injectable({
  providedIn: 'root',
})
export class DarkModeTogglerService {
  private readonly darkClassName = environment.DARK_CLASS_NAME;
  private readonly lightClassName = environment.LIGHT_CLASS_NAME;
  private readonly lightBg = environment.LIGHT_BG;
  private readonly darkBg = environment.DARK_BG;
  private readonly darkModeKey = environment.DARK_MODE_STORAGE_KEY;

  readonly isDarkMode$ = new BehaviorSubject<boolean>(
    !!JSON.parse(this.localStorageService.getValue(this.darkModeKey) as string)
  );

  constructor(
    private readonly overlay: OverlayContainer,
    private readonly localStorageService: LocalStorageService,
    private readonly meta: Meta
  ) {
    this.toggleTheme(this.isDarkMode$.getValue());
  }

  toggleTheme(darkMode: boolean) {
    this.localStorageService.setValue(this.darkModeKey, JSON.stringify(darkMode));

    if (darkMode) {
      this.overlay.getContainerElement().classList.add(this.darkClassName);
      this.overlay.getContainerElement().classList.remove(this.lightClassName);

      this.meta.updateTag({ name: 'apple-mobile-web-app-status-bar-style', content: this.darkBg });
      this.meta.updateTag({ name: 'theme-color', content: this.darkBg });

      document.body.classList.add(this.darkClassName);
      document.body.classList.remove(this.lightClassName);
    } else {
      this.overlay.getContainerElement().classList.add(this.lightClassName);
      this.overlay.getContainerElement().classList.remove(this.darkClassName);

      this.meta.updateTag({ name: 'apple-mobile-web-app-status-bar-style', content: this.lightBg });
      this.meta.updateTag({ name: 'theme-color', content: this.lightBg });

      document.body.classList.add(this.lightClassName);
      document.body.classList.remove(this.darkClassName);
    }

    this.isDarkMode$.next(darkMode);
  }
}
