import { Injectable } from '@angular/core';

import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { SanityBaseService } from './sanity-base.service';
import { LocalStorageService } from '@gan/core/util-local-storage';

import { pageQueryWithImage, sanityIntraClientCredentials } from '../helpers';

@Injectable()
export class SanityIntraService extends SanityBaseService {
  readonly config = sanityIntraClientCredentials;

  constructor(protected override readonly localStorageService: LocalStorageService) {
    super(localStorageService);
  }

  getSubscriptionInstructions(device: string) {
    const request = this.config.fetch(`* [slug.current == "staff-subscribe-${device}"] {${pageQueryWithImage}}[0]`);

    return fromPromise(request);
  }
}
