import * as moment from 'moment';
import { environment } from '@gan/core/util-environment';
import { LanguageEnum } from '@gan/core/feature-language';

/**
 * Date & Time Helper
 */
export class DateTimeHelper {
  /**
   * get date without time
   * @param dateValue - date value
   * @param dateFormat - date format
   */
  getDateByFormat(dateValue: Date, dateFormat: string) {
    return moment([dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate()]).format(dateFormat);
  }

  /**
   * get date
   * @param date - date
   */
  getDate(date: string) {
    const convertToDate = new Date(date);

    return moment([convertToDate.getFullYear(), convertToDate.getMonth(), convertToDate.getDate()]);
  }

  /**
   * get time
   * @param date - date
   */
  getTime(date: string): string {
    const convertToDate = new Date(date);
    const getMinutes = convertToDate.getMinutes() < 10 ? '0' + convertToDate.getMinutes() : convertToDate.getMinutes();
    const getHours = convertToDate.getHours() < 10 ? '0' + convertToDate.getHours() : convertToDate.getHours();

    return getHours + ':' + getMinutes;
  }

  /**
   * get current date
   * @param langValue the language to use for translating month
   */
  getCurrentDate(langValue: LanguageEnum): string {
    const currentDate = new Date();

    if (langValue === LanguageEnum.NO) {
      return currentDate.getDate() + ' ' + currentDate.toLocaleString('nb', { month: 'long' });
    }
    return currentDate.getDate() + ' ' + currentDate.toLocaleString('en', { month: 'long' });
  }

  /**
   * get submitted date formatted with day of week and month as text in localized manner
   * @param dateString the date as a string
   * @param langValue the language to use for translating weekday and month
   */
  getDateWithMonthAndWeekDay(dateString: string, langValue: string): string {
    const date = new Date(dateString);

    if (langValue === environment.LANGUAGE_DEFAULT) {
      const dayOfWeek = date.toLocaleString(environment.LANGUAGE_DEFAULT, { day: 'numeric' });
      return (
        date.toLocaleString(environment.LANGUAGE_DEFAULT, { weekday: 'long' }) +
        ' ' +
        date.toLocaleString(environment.LANGUAGE_DEFAULT, { month: 'long' }) +
        ' ' +
        this.getDayOfWeekInEnglish(dayOfWeek)
      );
    }
    const dayOfWeek = date.toLocaleString('nb', { weekday: 'long' });
    return (
      dayOfWeek.charAt(0).toUpperCase() +
      dayOfWeek.slice(1) +
      ' ' +
      date.toLocaleString('nb', { day: 'numeric' }) +
      ' ' +
      date.toLocaleString('nb', { month: 'long' })
    );
  }

  /**
   * Get the day of week as english with appropriate suffix
   * @param dayOfWeek the day of week
   */
  private getDayOfWeekInEnglish(dayOfWeek: string) {
    if (dayOfWeek === '2' || dayOfWeek === '22') {
      return dayOfWeek + 'nd';
    }
    if (dayOfWeek === '3' || dayOfWeek === '23') {
      return dayOfWeek + 'rd';
    }
    return dayOfWeek + 'th';
  }

  /**
   * get current time with seconds
   */
  getCurrentTimeWithSeconds(): string {
    const currentTime = new Date();
    const getMinutes = currentTime.getMinutes() < 10 ? '0' + currentTime.getMinutes() : currentTime.getMinutes();
    const getHours = currentTime.getHours() < 10 ? '0' + currentTime.getHours() : currentTime.getHours();
    const getSeconds = currentTime.getSeconds() < 10 ? '0' + currentTime.getSeconds() : currentTime.getSeconds();

    return getHours + ':' + getMinutes + ':' + getSeconds;
  }

  /**
   * get time plus one hour
   * @param time - time value
   */
  getTimePlusOneHour(time: string): string {
    const parsedTimeValue = this.parseTimePickerValues(time);

    return (parsedTimeValue.hours + 1).toString() + ':' + parsedTimeValue.minutes.toString();
  }

  /**
   * parse timePicker values
   * @param timeValue - time value
   */
  parseTimePickerValues(timeValue: string) {
    return {
      hours: +timeValue.split(':')[0],
      minutes: +timeValue.split(':')[1],
    };
  }

  /**
   * compare two date values by day
   * @param fromDate - from date value
   * @param toDate - to date value
   */
  compareTwoDates(fromDate, toDate) {
    return moment(fromDate).isSame(toDate, 'day');
  }

  /**
   * compare two date values by minutes
   * @param fromDate - from date value
   * @param toDate - to date value
   */
  compareTwoDatesByMinutes(fromDate, toDate) {
    return moment(fromDate).isSame(toDate, 'minutes');
  }

  /**
   * get date by value (yesterday|today|tomorrow)
   * @param value - string value (yesterday|today|tomorrow)
   */
  getDateByValue(value: string): Date {
    const currentDate = new Date();

    if (value === 'yesterday') {
      currentDate.setDate(currentDate.getDate() - 1);
    } else if (value === 'tomorrow') {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return currentDate;
  }

  /**
   * minus one day
   * @param dateValue - date value
   */
  minusOneDay(dateValue) {
    return new Date(dateValue.setDate(dateValue.getDate() - 1));
  }

  /**
   * plus one day
   * @param dateValue - date value
   */
  plusOneDay(dateValue) {
    return new Date(dateValue.setDate(dateValue.getDate() + 1));
  }

  /**
   * compare if two dates is equal
   * @param date1 - date 1
   * @param date2 - date 2
   */
  compareIfTwoDatesIsEqual(date1, date2) {
    return moment(date1).isSame(date2, 'day');
  }

  /**
   * prepare date to backend date format
   * @param dateValue - date value
   */
  prepareToBackendDateFormat(dateValue: string): string {
    return new Date(dateValue).toISOString();
  }

  /**
   *
   * @param time - time string (18:15)
   * @param delay - delay in seconds to add
   */
  addSecondsToTime(time: string, delay: number) {
    const now = moment();
    const timeArray = time.split(':') as [string, string];
    const withTime = now.hours(+timeArray[0]).minutes(+timeArray[1]);
    const withTimePlusDelay = withTime.add(delay, 'seconds');

    return `${withTimePlusDelay.hours()}:${withTimePlusDelay.minutes()}`;
  }

  /**
   * get difference in days between two dates
   * @param date1 - string
   * @param date2 - string
   */
  getDateDifferenceInDays(date1: string, date2: string): number {
    return moment(date1).diff(moment(date2), 'days');
  }
}
