import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ElementColor, ElementType } from '@gan/core/ui-kit';

@Component({
  selector: 'gan-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() classList: string;
  @Input() color: ElementColor | undefined;
  @Input() type: ElementType | undefined;
  @Input() disabled = false;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
