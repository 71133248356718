import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DarkModeTogglerService } from '../../services/dark-mode-toggler.service';

@Component({
  selector: 'gan-dark-mode-toggler',
  templateUrl: './dark-mode-toggler.component.html',
  styleUrls: ['./dark-mode-toggler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DarkModeTogglerComponent implements OnInit, OnDestroy {
  @Input() showLabel = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  toggleControl = new FormControl(false);

  constructor(private readonly darkModeTogglerService: DarkModeTogglerService) {}

  ngOnInit(): void {
    this.toggleControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((darkMode) => {
      this.darkModeTogglerService.toggleTheme(darkMode);
    });

    this.toggleControl.setValue(this.darkModeTogglerService.isDarkMode$.getValue());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
