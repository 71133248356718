import { ModuleWithProviders } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { SanityNorgeService } from '@gan/core/util-sanity';

import { SetTitleGuard } from '@gan/shared/ui-title';

const APP_ROUTES: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'gdpr',
        canActivate: [SetTitleGuard],
        data: {
          title: 'School Train',
        },
        loadChildren: () =>
          import('@gan/school/feature-gdpr').then(({ SchoolFeatureGdprModule }) => SchoolFeatureGdprModule),
      },
      {
        path: 'how-to',
        canActivate: [SetTitleGuard],
        data: {
          title: 'School Train',
        },
        loadChildren: () =>
          import('@gan/common/feature-how-to').then(({ CommonFeatureHowToModule }) => CommonFeatureHowToModule),
        providers: [{ provide: SanityNorgeService, useClass: SanityNorgeService }],
      },
      {
        path: '',
        pathMatch: 'full',
        canActivate: [SetTitleGuard],
        data: {
          title: 'School Train',
        },
        loadChildren: () =>
          import('@gan/school/feature-real-time-traffic-info').then(
            ({ SchoolFeatureRealTimeTrafficInfoModule }) => SchoolFeatureRealTimeTrafficInfoModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const RouterConfigData: ModuleWithProviders<RouterModule> = RouterModule.forRoot(APP_ROUTES, {
  preloadingStrategy: PreloadAllModules,
});
