import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'timeDiff',
})
export class TimeDiffPipe implements PipeTransform {
  transform(start: Date | string, end: Date | string, precise: moment.unitOfTime.Diff = 'minutes'): number {
    return moment(start).diff(moment(end), precise);
  }
}
