import { Injectable } from '@angular/core';

import { SIGNAL_R_PROPERTIES } from '../properties';

import { SignalREventModel, SignalRService } from '@gan/core/util-signalr';

import { NotificationMessageInterface } from '../interfaces';

/**
 * signal R service for school notification messages
 */
@Injectable()
export class NotificationMessagesSignalRService extends SignalRService<
  SignalREventModel<NotificationMessageInterface>[]
> {
  hubMethod = SIGNAL_R_PROPERTIES.method;

  constructor() {
    super();
  }

  /**
   * start signalR notification messages hub connection
   */
  override startHubConnection(hubName = SIGNAL_R_PROPERTIES.notificationMessagesHub) {
    return super.startHubConnection(hubName);
  }
}
