import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { ButtonComponent } from './components/button/button.component';
import { LinkComponent } from './components/link/link.component';

@NgModule({
  imports: [CommonModule, RouterModule, MatButtonModule],
  declarations: [ButtonComponent, LinkComponent],
  exports: [ButtonComponent, LinkComponent],
})
export class SharedUiButtonModule {}
