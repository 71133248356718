import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { Params } from '@angular/router';

import { ElementColor, ElementTarget, ElementType } from '@gan/core/ui-kit';

@Component({
  selector: 'gan-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Optional() @Input() routerLink: string | string[] | undefined;
  @Optional() @Input() queryParams: Params | null | undefined;
  @Optional() @Input() href: string | undefined;
  @Optional() @Input() target: ElementTarget | string | undefined;
  @Input() color: ElementColor | undefined;
  @Input() type: ElementType | undefined;
}
