/**
 * date time properties
 */
export const DATE_TIME_PROPERTIES = {
  defaultLocale: 'en-US',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultDateTimeFormat: 'dd.MM.yyyy HH:mm',
  defaultDateTimeWithSecondsFormat: 'dd.MM.yyyy HH:mm:ss',
  defaultTimeFormat: 'HH:mm',
  defaultTimeWithSecondsFormat: 'HH:mm:ss',
  kariHistoryDateTimeFormat: 'dd.MM.yy HH:mm:ss',
  siriHistoryDateTimeFormat: 'HH:mm:ss',
  datePickerFormat: 'DD.MM.YYYY',
  backendSideFormat: 'YYYY-MM-DD',
  datePipeFormat: 'DD.MM.YYYY',
  dayDateFormat: 'dddd DD',
  dayDateShortFormat: 'EEEE d.',
  dayDateOrdinalFormat: 'dddd Do',
  dayDateMonthFormat: 'EEEE dd. LLLL',
  dayDateShortMonthFormat: 'EEEE d. LLLL',
  dayDateMonthShortFormat: 'EEE dd. LLL',
  dayMonthFormat: 'd MMM',
  dayMonthFullFormat: 'd. MMMM',
  dayMonthYearFormat: 'd MMM y',
  dayMonthFullYearFormat: 'd. MMMM y',
  dayMonthTimeFormat: 'd MMM HH:mm',
  dayMonthYearTimeFormat: 'd MMM y HH:mm',
  monthYearFormat: 'MMMM y',
  defaultYearFormat: 'yyyy',
  defaultWeekdayFormat: 'dddd',
};

export const DATEPICKER_PROPERTIES = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: DATE_TIME_PROPERTIES.datePickerFormat,
    monthYearLabel: 'MMM YYYY',
  },
};
