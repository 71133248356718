import { EventEmitter } from '@angular/core';

import { HubConnection, IHttpConnectionOptions, HubConnectionBuilder } from '@microsoft/signalr';

import { environment } from '@gan/core/util-environment';

/**
 * SignalR Service that provides hub connection creation, start, restart and disconnect
 */
export abstract class SignalRService<T> {
  private hubConnection!: HubConnection;
  private hubName!: string;
  private connectionOptions: signalR.IHttpConnectionOptions = {};

  /**
   * Process received data
   * @param events T
   */
  protected hubCallback = (events: T) => this.hubEvent.emit(events);

  /**
   * Obligatory hub name
   */
  abstract hubMethod: string;

  readonly hubEvent: EventEmitter<T> = new EventEmitter();

  /**
   * register and receive train card hub events
   */
  private registerEventHandler() {
    this.hubConnection.on(this.hubMethod, this.hubCallback);
  }

  protected createHubConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.BASE_URL + '/' + environment.PREFIX + this.hubName, this.connectionOptions)
      .withAutomaticReconnect()
      .build();
  }

  /**
   * start signalR hub connection
   * @param hubName
   * @param options
   */
  startHubConnection(hubName?: any, options?: IHttpConnectionOptions) {
    this.hubName = hubName;
    this.connectionOptions = options ?? {};

    this.createHubConnection();

    return this.hubConnection
      .start()
      .then(() => {
        this.registerEventHandler();
      })
      .catch((err) => console.error(`${this.hubName} hub connection error: `, err));
  }

  /**
   * restart hub connection
   * @param hubName - hub name
   */
  restartHubConnection(hubName?: string) {
    return this.disconnectHub().then(() => {
      return this.startHubConnection(hubName, this.connectionOptions);
    });
  }

  /**
   * disconnect  hub
   */
  disconnectHub() {
    const connection = this.hubConnection?.stop() || Promise.resolve();

    return connection.then(() => {
      console.info(`${this.hubName} hub connection has been stopped`);
    });
  }
}
