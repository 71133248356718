import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { NotificationMessageInterface } from '@gan/common/data-notification-messages';

import { MethodTypeEnum, useNotification } from '@gan/common/ui-notification';
import { environment } from '@gan/core/util-environment';

@Injectable()
export class NotificationMessagesService {
  constructor(private readonly http: HttpClient) {}

  getNotificationMessages(): Observable<NotificationMessageInterface[]> {
    return this.http
      .get<NotificationMessageInterface[]>(`${environment.API_URL}/school-app/messages`)
      .pipe(useNotification({ method: MethodTypeEnum.GET, resourceName: 'School App Messages' }));
  }
}
