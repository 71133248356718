<div
  class="notification-container"
  [class]="notification.type.toLowerCase()"
  *ngFor="let notification of notifications"
>
  <span>{{ notification.message }}</span>
  <button mat-icon-button (click)="dismiss(notification)">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<gan-snackbar
  *ngFor="let snackbarNotification of snackbarNotifications"
  [notification]="snackbarNotification.notification"
  [type]="snackbarNotification.type"
  (dismiss)="dismissSnackbar(snackbarNotification)"
></gan-snackbar>
