import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationMessagesService, NotificationMessagesSignalRService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [NotificationMessagesService, NotificationMessagesSignalRService],
})
export class CommonDataNotificationMessagesModule {}
