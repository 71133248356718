import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

import { NotificationModel } from '../../models/notification-model';
import { NotificationService } from '../../services/notification.service';
import { notifications$ } from '../../utils/util';
import { SnackbarInterface } from '@gan/shared/ui-snackbar';

@Component({
  selector: 'gan-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent implements AfterViewInit {
  notifications: NotificationModel[] = [];
  snackbarNotifications: SnackbarInterface[] = [];

  constructor(private cdr: ChangeDetectorRef, private service: NotificationService) {
    this.service.pool.subscribe((notification) => {
      this.notifications.push(notification);
      this.cdr.detectChanges();
      setTimeout(() => {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
        this.cdr.detectChanges();
      }, notification.time);
    });

    notifications$.subscribe((notification) => {
      if (this.snackbarNotifications.length === 5) {
        // maximum 5 notifications at once
        this.snackbarNotifications.splice(0, 1); // delete oldest notification
      }

      this.snackbarNotifications.push(notification);
      this.cdr.detectChanges();

      setTimeout(() => this.dismissSnackbar(notification), notification.time);
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detach();
  }

  dismiss(notification: NotificationModel) {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
    this.cdr.detectChanges();
  }

  dismissSnackbar(notification: SnackbarInterface): void {
    this.snackbarNotifications.splice(this.snackbarNotifications.indexOf(notification), 1);
    this.cdr.detectChanges();
  }
}
