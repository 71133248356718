import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';

import { DATE_TIME_PROPERTIES } from '@gan/core/util-date-time-helper';

@Injectable()
export class UiDateService {
  private dateTimeProps = DATE_TIME_PROPERTIES;

  constructor(private readonly translateService: TranslateService) {}

  toOrdinalDate(date: Date | string | number) {
    return moment(date).format(this.dateTimeProps.dayDateOrdinalFormat);
  }

  toDateTimeString(date: Date | string | number): string {
    return moment(date).format(`${this.dateTimeProps.datePipeFormat} ${this.dateTimeProps.defaultTimeFormat}`);
  }

  toDateString(date: Date | string | number): string {
    return moment(date).format(this.dateTimeProps.datePipeFormat);
  }

  toTimeString(date: Date | string | number): string {
    return moment(date).format(this.dateTimeProps.defaultTimeFormat);
  }

  toRelativeDateString(date: Date | string | number): string {
    const valDate = moment(date).local().startOf('day');
    const nowDate = moment().local().startOf('day');

    const days = nowDate.diff(valDate, 'days');
    const translatedToday = this.translateService.instant('COMMON.RELATIVE_TIME.TODAY');

    if (days >= 1) {
      return days < 2
        ? this.translateService.instant('COMMON.RELATIVE_TIME.YESTERDAY')
        : moment(date).local().format(this.dateTimeProps.datePipeFormat);
    }

    if (days < 0) {
      // future dates
      return days >= -1
        ? this.translateService.instant('COMMON.RELATIVE_TIME.TOMORROW')
        : moment(date).local().format(this.dateTimeProps.dayDateFormat);
    }

    return translatedToday;
  }

  toRelativeTimeString(date: Date | string | number): string {
    const valDate = moment(date).local();
    const nowDate = moment().local();

    const days = nowDate.diff(valDate, 'days');
    const hours = nowDate.diff(valDate, 'hours');
    const mins = nowDate.diff(valDate, 'minutes');

    if (days >= 1) {
      return valDate.format(this.dateTimeProps.defaultTimeFormat);
    }

    if (hours) {
      return this.translateService.instant('COMMON.RELATIVE_TIME.X_HOURS_AGO', {
        value: hours,
      });
    }

    if (mins) {
      return this.translateService.instant('COMMON.RELATIVE_TIME.X_MINUTES_AGO', {
        value: mins,
      });
    }

    return this.translateService.instant('COMMON.RELATIVE_TIME.NOW');
  }
}
