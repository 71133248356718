import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalStorageService } from '@gan/core/util-local-storage';
import { environment } from '@gan/core/util-environment';

/**
 * Sets current language value into `Accept-Language` header
 */
@Injectable({
  providedIn: 'root',
})
export class SetLanguageInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.setLanguageHeaders(req);

    return next.handle(req).pipe(
      catchError((error: HttpEvent<any>) => {
        return throwError(error);
      })
    );
  }

  /**
   * set correct language headers for http request
   * @param req - http request
   */
  private setLanguageHeaders(req) {
    const getLanguageValue = this.localStorageService.getLang() || environment.LANGUAGE_DEFAULT;
    const headers = new HttpHeaders().set('Accept-Language', getLanguageValue);

    return req.clone({ headers });
  }
}
