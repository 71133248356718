import { createClient } from '@sanity/client';

import { environment } from '@gan/core/util-environment';

export const sanityIntraClientCredentials = createClient({
  projectId: environment.SANITY_INTRA_PROJECT_ID,
  dataset: environment.SANITY_INTRA_DATASET,
});

export const sanityNorgeClientCredentials = createClient({
  projectId: environment.SANITY_NORGE_PROJECT_ID,
  dataset: environment.SANITY_NORGE_DATASET,
});
