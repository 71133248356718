import { SanityClient } from '@sanity/client';

import { Observable } from 'rxjs';

import { LanguageEnum } from '@gan/core/feature-language';
import { LocalStorageService } from '@gan/core/util-local-storage';

export abstract class SanityBaseService {
  abstract config: SanityClient;

  get selectedLanguage() {
    return this.localStorageService.getLang() === LanguageEnum.EN
      ? 'en-GB'
      : this.localStorageService.getLang()?.toLowerCase();
  }

  constructor(protected readonly localStorageService: LocalStorageService) {}

  abstract getSubscriptionInstructions(device: string): Observable<any>;
}
