import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserNotificationPlayerCreateInput } from '../models/user-notification-player-create-input.model';
import { environment } from '@gan/core/util-environment';

@Injectable()
export class UserNotificationsApiService {
  constructor(private readonly http: HttpClient) {}

  createUserNotificationSubscription(payload: UserNotificationPlayerCreateInput) {
    return this.http.post<void>(`${environment.API_URL}/user-notification-players`, payload);
  }
}
