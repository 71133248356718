import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { UserNotificationsApiService } from './services/user-notifications-api.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [UserNotificationsApiService],
})
export class CommonDataUserNotificationsModule {}
