<main class="main-container">
  <gan-header></gan-header>

  <gan-notification-messages></gan-notification-messages>

  <div class="px-2 pb-12">
    <router-outlet></router-outlet>
  </div>

  <gan-footer></gan-footer>
</main>
